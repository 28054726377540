<template>
    <div>
        <page-header
            :title="$t('pages.autoBalanceTransfer.selectAmount.title')"
            show-back
            @onClose="returnToRoot"
        />

        <div class="container">
            <loading-indicator
                v-show="loading"
                :title="$t('global.loadingMessage.loading')"
            />

            <form-container
                ref="transferAmountForm"
                @onSubmit="submitBalanceTransferAmount"
            >
                <p
                    class="mb-2"
                    v-html="$t('pages.autoBalanceTransfer.selectAmount.subtitle', { accountName })"
                />
                <div
                    class="alert alert-warning text-start mt-2"
                    v-if="errorMessage"
                    role="alert"
                >
                    {{ errorMessage }}
                </div>

                <form-field-currency
                    class="mb-2"
                    name="transfer_amount"
                    :placeholder="$t('pages.autoBalanceTransfer.selectAmount.placeholder')"
                    v-model="amount"
                    validation-mode="passive"
                />
                <base-button
                    type="submit"
                    :submitting="submitting"
                    class="mb-3"
                >
                    {{ $t('pages.autoBalanceTransfer.selectAmount.cta') }}
                </base-button>
            </form-container>

            <div class="d-flex">
                <div class="me-2">
                    <img
                        class="list-item-icon"
                        src="@/assets/images/global/circle-line-through.svg"
                        alt="No transfer fees"
                    >
                </div>
                <div>
                    <p class="fw-bold mb-0 mt-half">
                        {{ $t('pages.autoBalanceTransfer.selectAmount.noTransferFee.title') }}
                    </p>
                    <p>
                        {{ $t('pages.autoBalanceTransfer.selectAmount.noTransferFee.message') }}
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import LoadingIndicator from '@/components/LoadingIndicator'
    import { logger } from '@/utils/logger'
    import FormFieldCurrency from '@/components/base/FormFieldCurrency'
    import PageHeader from '@/components/PageHeader'
    import { navigation } from '@/mixins/navigation'
    import { getActiveBalanceTransferAccount } from '@/services/api'
    import { mapActions } from 'vuex'
    import { i18n } from '@/utils/i18n'
    import { RoutePaths } from '@/routes/router'
    import FormContainer from '@/components/base/FormContainer'
    import BaseButton from '@/components/base/BaseButton'

    export default {
        name: 'SelectRecurringBalanceTransferAmount',
        components: {
            FormContainer,
            PageHeader,
            LoadingIndicator,
            FormFieldCurrency,
            BaseButton,
        },
        mixins: [navigation],
        props: {
            token: {
                type: String,
                required: true,
                RoutePaths,
            },
        },
        data: function () {
            return {
                loading: false,
                accountName: null,
                amount: null,
                errorMessage: null,
                submitting: false,
            }
        },
        mounted: async function () {
            this.loading = true
            try {
                logger.info(`Getting active balance transfer account associated with token ${this.token}`)
                const accountResponse = await getActiveBalanceTransferAccount(this.token)
                const accountPayload = accountResponse.data.payload
                this.accountName = accountPayload.accountName
                this.$logEvent('view_recurring_balance_transfer_enter_amount', { account: accountPayload })
            } catch (e) {
                // Todo Maybe do something better on an error than just exiting the screen?
                await this.returnToRoot()
                logger.error(`Couldn't load balance transfer account for token ${this.token}. Error ${e.message}`)
            } finally {
                this.loading = false
            }
        },
        methods: {
            ...mapActions(['balanceTransferForRecurringAccount']),
            submitBalanceTransferAmount: async function () {
                const isValid = await this.$refs.transferAmountForm.$refs.observer.validate()
                if (!isValid) {
                    return
                }

                const transfer = { accountName: this.accountName, token: this.token, amount: this.amount }
                try {
                    this.$logEvent('click_button_submit_recurring_balance_transfer', { transfer })
                    logger.info(`Submitting recurring balance transfer amount of ${this.amount}`)
                    this.errorMessage = null
                    this.submitting = true
                    await this.balanceTransferForRecurringAccount(transfer)
                    await this.$router.replace(RoutePaths.RECURRING_BT_REQUESTED)
                } catch (e) {
                    logger.error(`Error initiating balance transfer ${JSON.stringify(transfer)}`)
                    this.errorMessage = i18n.t('pages.autoBalanceTransfer.errorMessage')
                } finally {
                    this.submitting = false
                }
            },
        },
    }
</script>

<style scoped>
    .mt-half {
        margin-top: 4px;
    }
</style>
